import React from "react";

import Layout from "../components/Layout";
import Title from "../components/Title";

const PrivacyPage = ({ path }) => {
  return (
    <Layout currentPath={path} title="Privacy">
      <Title label="Tinkertax Privacy Policy" />

      <section className="container mx-auto text-gray-600 px-4">
        <p>
          <em>Updated: 23 May 2018</em>
        </p>
        <p>
          <strong>Privacy Statement</strong>
        </p>
        <p>
          This Privacy Notice explains how Personal Data (defined below) is
          collected, used and disclosed by us, TinkerTax LLP, in relation to
          your access and use of our online Service (the “
          <strong>Service</strong>”), which are provided through TinkerTax
          website and/or web applications (collectively, “<strong>Site</strong>
          ”). This Privacy Notice applies to all Personal Data collected or
          submitted to TinkerTax through the use of the Service. By accessing,
          using and providing us with any Personal Data, you are: (i) expressly
          consenting and (ii) responsible for obtaining the express consent of
          your employees or the persons whose Personal Data you are disclosing
          with respect, to the manner in which we collect, use, disclose and
          transfer Personal Data as set out below.
        </p>
        <p>
          <strong>Nature of Personal Data Collected</strong>
        </p>
        <p>
          We limit the collection of Personal Data to that which we need to
          administer and improve the Site, to provide our Service to you, and to
          fulfill any legal and regulatory requirements.
        </p>
        <p>
          The categories of Personal Data (collectively, “
          <strong>Personal Data</strong>”) that we collect may include, but are
          not limited to your name and email address.
        </p>
        <p>
          <strong>How Personal Data is Collected</strong>
        </p>
        <p>
          We do not require you to provide any Personal Data in order to have
          general access to the Site. However, in order to access or use certain
          information, features or Service, you may be required to provide
          Personal Data. The circumstances under which Personal Data is
          primarily collected may include, but are not limited:
        </p>
        <ol className="list-decimal list-inside">
          <li>When you register an account to access and use the Service;</li>
          <li>When you make payment for the Service;</li>
          <li>
            When you enter your email to request for certain information in our
            website; and
          </li>
          <li>
            When you provide us with comments or suggestions, request
            information about our Service via phone, email or other forms of
            communication.
          </li>
        </ol>
        <p>
          <strong>How Personal Data is Used and Disclosed</strong>
        </p>
        <p>
          We use and disclose the Personal Data you provide us to perform the
          Service you have requested. We limit the purposes for which we use and
          disclose Personal Data to:
        </p>
        <ol className="list-decimal list-inside">
          <li>Providing the Service to you;</li>
          <li>
            Sharing Personal Data with third parties (including related parties
            of TinkerTax and overseas parties) that may be involved in the
            delivery of TinkerTax Services to you;
          </li>
          <li>
            Administering and improving the Service which includes the Site;
          </li>
          <li>
            Providing you with information about the Service, new Services or
            products or upcoming events;
          </li>
          <li>Responding to your queries, requests and instructions;</li>
          <li>
            Troubleshooting i.e. analysing and solving problems arising from
            your use of the Service;
          </li>
          <li>
            Complying with legal requirements, the requests of public agencies,
            law enforcement and regulatory officials, or orders of court;
          </li>
          <li>Sending you email notifications;</li>
          <li>Processing payments you authorize for the Service;</li>
          <li>
            Fulfilling any other purposes for which we have obtained your
            consent.
          </li>
        </ol>
        <p>
          All financial transactions effected are handled by our service
          providers which include banks and credit companies, each of whom have
          their respective privacy notices.
        </p>
        <p>
          We will not: (a) without your express consent, provide Personal Data
          to any third parties for the purpose of direct marketing; (b) disclose
          Personal Data except to those to whom disclosure is necessary to
          effect the purposes of the Service and who are similarly bound to hold
          Personal Data in confidence; or (c) disclose Personal Data unless
          required to do so by law or in the good faith belief that such
          preservation or disclosure is reasonably necessary to: (i) comply with
          legal process; (ii) respond to claims that any of the Personal Data or
          content violates the rights of third parties; or (iii) protect
          TinkerTax’s rights, property, or personal safety and that of
          TinkerTax’s customers, users or the public.
        </p>
        <p>
          <strong>Retention Obligations</strong>
          <br />
          TinkerTax will retain Personal Data we process on your behalf for as
          long as is needed to provide the Service to you and your employees.
          TinkerTax may retain and use Personal Data for as long as necessary as
          required by law or for other legitimate business purposes including
          complying withTinkerTax’s legal obligations, resolving disputes, and
          enforcingTinkerTax’s agreements.
        </p>
        <p>
          <strong>Access and Correction Obligations</strong>
        </p>
        <p>
          You and/or your employees may access, review, update and correct
          Personal Data through your respective Accounts or by contacting us.
          TinkerTax shall respond to an access request as soon as reasonably
          possible and make a correction as soon as practicable. A reasonable
          fee may be charged by TinkerTax to provide access to archived Personal
          Data as mentioned above.
        </p>
        <p>
          <strong>Protection Obligations</strong>
        </p>
        <p>
          The security of Personal Data, is very important to TinkerTax.
          TinkerTax will maintain reasonable security arrangements in respect of
          Personal Data to prevent unauthorised access, collection, use,
          disclosure, copying, modification, disposal or similar risks. However,
          despite such reasonable efforts, we cannot guarantee the security of
          Personal Data transmitted through the Internet. TinkerTax will
          implement technical and organizational measures in accordance with
          good industry practice such as encryption, alphanumeric passwords and
          user access controls that can be customised according to your
          preferences. You are solely responsible for keeping all passwords
          confidential and configuring the appropriate access control settings.
        </p>
        <p>
          <strong>Transfer Obligations</strong>
        </p>
        <p>
          Personal Data may be accessed by or transferred to our servers
          anywhere in the world, as is necessary to facilitate the provision of
          the Service by TinkerTax to you. Your Personal Data may also be
          accessed by or transferred to our affiliates and data processors
          and/or intermediaries for the purposes of providing you with the
          Service. When transferring Personal Data outside of your jurisdiction,
          TinkerTax will take reasonable steps to ensure that the privacy and
          security of Personal Data is accorded a standard of protection
          comparable to the protection under your local data protection laws. By
          providing TinkerTax with Personal Data, you consent (and have obtained
          the consent of your employees or the persons whose Personal Data you
          are disclosing) to such transfer and/or access.
        </p>
        <p>
          <strong>Anonymization of Personal Data</strong>
        </p>
        <p>
          TinkerTax may anonymize Personal Data so that the resulting data lacks
          any means to identify the user (“Anonymized Data”). TinkerTax may use
          the Anonymized Data for any commercial or non-commercial purpose
          including but not limited to the following:
        </p>
        <ol className="list-decimal list-inside">
          <li>to aggregate the Anonymized Data with other data;</li>
          <li>
            to modify the Anonymized Data (whether aggregated or not) so as to
            create a derivative work;
          </li>
          <li>
            to publicly publish the Anonymized Data (whether aggregated,
            modified or not) either directly or indirectly through third
            parties; and
          </li>
          <li>
            to commercialize the Anonymized Data (whether aggregated, modified
            or not) by licensing, selling and/or marketing the Anonymized Data
            (whether aggregated, modified or not) to third parties.
          </li>
        </ol>
        <p>
          Ownership of the Anonymized Data (including all intellectual property
          rights), as modified by TinkerTax, shall vest in and be owned
          absolutely by TinkerTax.
        </p>
        <p>
          <strong>Changes to Privacy Notice</strong>
        </p>
        <p>
          TinkerTax may revise this Privacy Notice from time to time due to
          legislative changes, changes in technology or our privacy practices or
          new uses of personally identifiable information not previously
          disclosed in this Privacy Notice. Material revisions will be effected
          by posting a notice on the Site prior to the change becoming effective
          or by sending you an email or other notification. Your continued
          access and/or use of the Service will indicate your acceptance of
          those changes. We encourage you to periodically refer to this Privacy
          Notice for the latest information on our privacy practices.
        </p>
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          Questions or comments related to this Privacy Notice and/or complaints
          should be submitted to the following designated person:
        </p>
        <p>
          Ng Thiam Hock
          <br />
          thiamhock@tinkertax.com
        </p>
      </section>
    </Layout>
  );
};

export default PrivacyPage;
